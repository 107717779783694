import { FC } from 'react'
import styles from './Preloader.module.css'

const PreloaderCard: FC= () => {
	return (
		<div
			className='h-min overflow-hidden rounded-lg shadow-lg'
		>
			<div
				className={styles.skeleton + ' min-h-[192px] w-full lg:h-48 md:h-36'}
			/>
			<div className='p-6'>
				<p className={styles.skeleton_item_title} />
				<div className='flex items-center flex-wrap '>
					<p className={styles.skeleton_text + ' mr-3 items-center py-1'} />
					<p className={styles.skeleton_text + ' mr-3 items-center py-1'} />
					<p className={styles.skeleton_text + ' mr-3 items-center py-1'} />
					<p className={styles.skeleton_text + ' mr-3 items-center py-1'} />
				</div>
			</div>
		</div>
	)
}
export default PreloaderCard
