import { Image } from 'antd'
import { FC, useState } from 'react'
import { BsZoomIn } from 'react-icons/bs'
import { imageUrl } from '../../data'
import { IProduct } from '../../interfaces'
import Heading from '../heading/Heading'

const AboutProduct: FC<{ product?: IProduct, id: string }> = ({ product, id }) => {
	const [imageLoaded, setImageLoaded] = useState(false)

	return (
		<section>
			<Heading title="Про товар:" />
			<div
				className="flex flex-col lg:flex-row gap-x-12 gap-y-6 container mx-auto max-w-6xl pt-10 px-2"
				style={{ viewTransitionName: `productBox${id}` }}
			>
				<div className="lg:w-1/2 w-full" style={{ viewTransitionName: `productImage${id}` }}>
					{!imageLoaded && <div className="flex items-center justify-center h-full">
						<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500" />
					</div>
					}
					<Image
						src={imageUrl + product?.urlToImg}
						rootClassName={`w-full ${!imageLoaded && ' hidden'}`}
						className="rounded-2xl"
						preview={{
							mask: (
								<p className='flex flex-row items-center gap-x-2'>
									<BsZoomIn />
									<span>Переглянути фото</span>
								</p>
							),
							maskClassName: 'rounded-xl'
						}}

						onLoad={() => setImageLoaded(true)}
					/>
				</div>
				<div className="lg:w-1/2 w-full flex flex-col gap-y-4 text-center lg:text-start">
					<h1
						className="text-2xl lg:text-4xl text-gray-800 font-semibold"
						style={{ viewTransitionName: `productTitle${id}` }}
					>
						{product?.title}
					</h1>
					<p
						dangerouslySetInnerHTML={{ __html: product?.description || '' }}
						className="leading-8"
					/>
					{product?.visiting &&
						<p className="text-sm">
							Переглянуто {product?.visiting} раз
						</p>
					}
					<div className="text-red-900 text-xl lg:text-3xl font-semibold ">
						{[product?.price, product?.priceTitle].join(' ')}
					</div>

				</div>
			</div>
		</section>
	)
}
export default AboutProduct
