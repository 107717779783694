import { Link } from 'react-router-dom'
import { Result } from 'antd'
import { FC } from 'react'
import AppHelmet from '../components/AppHelmet'

const ErrorPage: FC = () => {
	return (
		<>
			<AppHelmet title='Сторінку не знайдено' />
			<Result
				title='Сторінку не знайдено'
				status='404'
				extra={<Link to='/'>Повернутися на головну</Link>}
			/>
		</>
	)
}

export default ErrorPage
