import { ToastContainer } from 'react-toastify'
import FeedbackForm from '../components/contact/FeedbackForm'
import AppHelmet from '../components/AppHelmet'
import { FC } from 'react'

const ContactPage: FC = () => {
	return (
		<>
			<AppHelmet title='Відгук' />
			<FeedbackForm />
			<ToastContainer
				position='top-right'
				closeOnClick
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='colored'
			/>
		</>
	)
}
export default ContactPage
