import WeddingLoavesPage from '../pages/WeddingLoaves'
import DessertsPage from '../pages/DessertsPage'
import WeddingCakes from '../pages/WeddingCakes'
import PicturesPage from '../pages/PicturesPage'
import ContactPage from '../pages/ContactPage'
import ProductPage from '../pages/ProductPage'
import Cakes18Page from '../pages/Cakes18Page'
import { Route, Routes } from 'react-router-dom'
import CakesPage from '../pages/CakesPage'
import ErrorPage from '../pages/ErrorPage'
import HomePage from '../pages/HomePage'
import { FC } from 'react'

const AppRouter: FC = () => {
	return (
		<Routes>
			<Route path='/' element={<HomePage />} />
			<Route path='*' element={<ErrorPage />} />
			<Route path='/cakes' element={<CakesPage />} />
			<Route path='/contact' element={<ContactPage />} />
			<Route path='/cakes18' element={<Cakes18Page />} />
			<Route path='/pictures' element={<PicturesPage />} />
			<Route path='/desserts' element={<DessertsPage />} />
			<Route path='/product/:id' element={<ProductPage />} />
			<Route path='/wedding/cakes' element={<WeddingCakes />} />
			<Route path='/wedding/loaves' element={<WeddingLoavesPage />} />
		</Routes>
	)
}

export default AppRouter
