import Dragger from 'antd/es/upload/Dragger'
import Heading from '../heading/Heading'
import { toast } from 'react-toastify'
import { Form, Input } from 'antd'
import { $ky } from '../../api/ky'
import { FC } from 'react'

const FeedbackForm: FC = () => {
	const [form] = Form.useForm()
	const handleContactMe = async (contactData: any) => {
		const { data } = await $ky.post('review', contactData).json<any>()
		if (data?.status === true) {
			toast.success(
				'Відгук успішно додано, незабаром він зявиться на сайті!'
			)
		} else {
			toast.error('Ви не вибрали фото!')
		}

	}

	return (
		<div className="w-full max-w-4xl p-5 mx-auto mb-3">
			<Heading title="Напишіть ваш відгук:" />
			<Form form={form} onFinish={handleContactMe}>
				<Form.Item label="Імя та прізвище" required name="full_name">
					<Input />
				</Form.Item>
				<Form.Item label="Ваше повідомлення" required name="message">
					<Input.TextArea rows={3} />
				</Form.Item>
				<Form.Item
					label="Виберіть ваше фото:"
					required
					name="file"
					valuePropName="fileList"
				>
					<Dragger>
						<p className="p-4 text-gray-500">
							Натисніть або перетягніть файл у цю область, щоб завантажити
						</p>
					</Dragger>
				</Form.Item>
				<Form.Item>
					<button className="mt-2 w-full justify-center py-2 rounded-lg text-white bg-purple-600 hover:bg-purple-700">
						Додати
					</button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default FeedbackForm
