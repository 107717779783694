import { FC } from 'react'
import AppHelmet from '../components/AppHelmet'
import FeedBackList from '../components/contact/FeedBackList'
import HomeSlider from '../components/home-slider/HomeSlider'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct, IReview } from '../interfaces'

const HomePage: FC = () => {
	const { data: slider } = useCachedKy<IProduct[]>('similarProducts/55')
	const { data: cakes } = useCachedKy<IProduct[]>('cakes/8')
	const { data: desserts } = useCachedKy<IProduct[]>('desserts/8')
	const { data: reviews } = useCachedKy<IReview[]>('reviews/0')

	return (
		<>
			<AppHelmet title="Головна сторінка" />
			<HomeSlider products={slider} />
			<ProductList
				title="Тортики:"
				products={cakes}
				button={{ url: '/cakes', message: 'Більше тортиків' }}
			/>
			<ProductList
				title="Десерти:"
				products={desserts}
				button={{ url: '/desserts', message: 'Більше десертів' }}
			/>
			<FeedBackList reviews={reviews} />
		</>
	)
}
export default HomePage
