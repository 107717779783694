import { FC, useState } from 'react'
import { BiCake } from 'react-icons/bi'
import { BsX } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'

const HeaderBanner: FC = () => {
	const { pathname } = useLocation()
	const [open, setOpen] = useState(
		Boolean(+(localStorage?.getItem('banner-show') || pathname === '/'))
	)
	return open ? (
		<div className="bg-purple-600">
			<div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between flex-wrap">
					<div className="w-0 flex-1 flex items-center">
						<span className="flex p-2 rounded-lg bg-purple-800">
							<BiCake className="h-6 w-6 text-white" aria-hidden="true" />
						</span>
						<p className="ml-3 font-medium text-white sm:text-sm truncate">
							<span className="md:hidden">
								Перегляньте найпопулярніший товар
							</span>
							<span className="hidden md:inline ">
								Перегляньте найпопулярніший товар сьогодні!
							</span>
						</p>
					</div>
					<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						<Link
							to="/product/16"
							className="flex items-center justify-center px-4 py-2 border
              border-transparent rounded-lg shadow-sm text-sm font-medium text-purple-600 bg-white hover:bg-purple-200"
						>
							Детальніше
						</Link>
					</div>
					<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
						<button
							type="button"
							className="-mr-1 flex p-2 rounded-md hover:bg-purple-500
                focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
							onClick={() => {
								localStorage.setItem('banner-show', '0')
								setOpen(false)
							}}
						>
							<span className="sr-only">Закрити</span>
							<BsX className="h-6 w-6 text-white" aria-hidden="true" />
						</button>
					</div>
				</div>
			</div>
		</div>
	) : null
}
export default HeaderBanner
