import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IButton, IProduct } from '../../../interfaces'
import Heading from '../../heading/Heading'
import PreloaderCard from '../../preloader/PreloaderCard'
import ProductCard from './ProductCard'

const ProductList: FC<{
	title: string
	products?: IProduct[]
	button?: IButton
}> = ({ products, button, title }) => {
	return (
		<section>
			<div className='max-w-2xl mx-auto py-10 px-4 sm:py-12 sm:px-6 lg:max-w-7xl'>
				<Heading title={title} size={2} />
				<div className='grid grid-cols-2 gap-y-4 sm:grid-cols-3 gap-x-2 lg:grid-cols-4 xl:gap-x-8'>
					{products
						? products.map((product, index) => (
								<ProductCard key={index} product={product} />
							))
						: Array(8)
								.fill(null)
								.map((_, index) => <PreloaderCard key={index} />)}
				</div>
				{button && (
					<div className='flex mt-10 mb-1 w-full '>
						<Link
							to={button.url}
							className=' mx-auto px-8 py-2 border rounded-full text-base transition  font-small text-white bg-purple-500 hover:bg-purple-700 hover:scale-105'
						>
							{button.message}
						</Link>
					</div>
				)}
			</div>
		</section>
	)
}
export default ProductList
