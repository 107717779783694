import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

export default function DessertsPage() {
	const { data } = useCachedKy<IProduct[]>('desserts')

	return (
		<>
			<AppHelmet title="Десерти" />
			<ProductList title="Десерти:" products={data} />
		</>
	)
}
