import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

export default function CakesPage() {
	const { data } = useCachedKy<IProduct[]>('cakes')
	return (
		<>
			<AppHelmet title="Торти" />
			<ProductList title="Тортики:" products={data} />
		</>
	)
}
