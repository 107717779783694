import { FC } from 'react'
import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

const WeddingCakesPage: FC = () => {
	const { data } = useCachedKy<IProduct[]>(`weddingcakes`)


	return (
		<>
			<AppHelmet title="Весільні торти" />
			<ProductList title="Весільні торти:" products={data} />
		</>
	)
}
export default WeddingCakesPage
