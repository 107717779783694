import { Button, Dropdown, Menu } from 'antd'
import { MenuDividerType, MenuItemGroupType, MenuItemType, SubMenuType } from 'antd/es/menu/hooks/useItems'
import { FC } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { GiCakeSlice, GiCupcake, GiStairsCake } from 'react-icons/gi'
import { TbBrandCakephp, TbPhotoFilled } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'

type ItemType = MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType

const generalRoutes: ItemType[] = [
	{
		label: 'Головне меню',
		key: '/',
		children: [
			{ key: '/cakes', label: 'Торти', icon: <GiCakeSlice /> },
			{ key: '/desserts', label: 'Десерти', icon: <GiCupcake /> },
			{ key: '/pictures', label: 'Вафельні картинки', icon: <TbPhotoFilled /> }
		]
	},
	{
		label: 'Весільне меню',
		key: '/#',
		children: [
			{
				label: 'Весільні Торти',
				key: '/wedding/cakes',
				icon: <GiStairsCake />
			},
			{
				label: 'Весільні Короваї',
				key: '/wedding/loaves',
				icon: <TbBrandCakephp />
			}
		]
	},
	{
		label: 'Відгук',
		key: '/contact'
	}
]

const Header: FC = () => {
	const navigate = useNavigate()

	const onMenuClick = async (key: string) =>
		navigate(key)


	return (
		<div className="bg-white sticky top-0 z-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 ">
				<div
					className="flex justify-between items-center border-b-2 border-gray-300 pt-3 pb-1 md:justify-end md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<img className="h-8 w-auto mr-2" src="/icons/icon-384x384.png" alt="" />
						<Link to="/" className="text-2xl text-gray-900">
							OLGACAKE
						</Link>
					</div>

					<Menu
						onClick={e => onMenuClick(e.key)}
						items={generalRoutes}
						mode="horizontal"
						className="w-1/3 hidden md:block"
					/>

					<Dropdown
						menu={{
							items: generalRoutes,
							onClick: e => onMenuClick(e.key)
						}}
						placement="top"
						arrow
					>
						<Button className="md:hidden">
							<AiOutlineMenu className="text-xl" />
						</Button>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}
export default Header
