import { Carousel } from 'antd'
import { FC } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { imageUrl } from '../../data'
import { IProduct } from '../../interfaces'

const Slide: FC<{ product: IProduct }> = ({ product }) => {
	const navigate = useNavigate()

	const onClick = async () => {
		// logEvent('products', 'view-product', `id`, +product.id)
		;(document as any)?.startViewTransition(() => {
			flushSync(() => navigate(`/product/${product.id}`))
		})
	}

	return (
		<div
			onClick={onClick}
			className='cursor-pointer rounded-2xl'
		>
			<img
				src={imageUrl + product.urlToImg}
				alt={product.title}
				loading='eager'
				className='h-[55vh] w-full object-cover rounded-2xl'
			/>
			<h2
				className='text-white absolute bottom-0 font-semibold p-3 backdrop-brightness-75 backdrop-blur-sm w-full rounded-2xl'
			>
				{product.title}
			</h2>
		</div>
	)
}

const HomeSlider: FC<{ products?: IProduct[] }> = ({ products }) => {
	return (
		<Carousel
			autoplay
			slidesToShow={1}
			rootClassName='rounded-2xl'
			className='py-4 container mx-auto max-w-7xl lg:px-8 h-[55vh] rounded-2xl'
		>
			{products?.map(value => <Slide product={value} key={value.id} />)}
		</Carousel>
	)
}

export default HomeSlider
