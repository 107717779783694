import { useEffect, useState } from 'react'
import { $ky } from '../api/ky'

interface CachedKyResponse<T> {
	data?: T
}

export const useCachedKy = <T>(url: string): CachedKyResponse<T> => {
	const [data, setData] = useState<{ key?: string, data?: T }>()

	useEffect(() => {
		const cacheKey = JSON.stringify(url)
		if (data?.key === cacheKey) return
		(async () => {
			try {
				const cachedResponseJSON = sessionStorage.getItem(cacheKey)
				if (cachedResponseJSON) {
					const { data, timestamp } = JSON.parse(cachedResponseJSON)
					const elapsedTime = (new Date().getTime() - timestamp) / (1000 * 60)
					if (elapsedTime <= 15) {
						console.log('used-previous-cache-response', cacheKey, 'with-time-remaining', elapsedTime)
						setData({ key: cacheKey, data })
						return
					} else console.log('delete-previous-cache-response',cacheKey, 'with-time-remaining', elapsedTime)
				}
				console.log('send-new-api-request', cacheKey)
				const response = await $ky(url).json<T>()
				const newCachedResponse = { data: response, timestamp: new Date().getTime() }
				sessionStorage.setItem(cacheKey, JSON.stringify(newCachedResponse))
				setData({ key: cacheKey, data: response })
			} catch (error: any) {
			}
		})()
		return
	}, [data?.key, url])

	return { data: data?.data }
}