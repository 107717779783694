import { FC } from 'react'

const Heading: FC<{ title: string; size?: number }> = ({ title, size }) => {
	const textSize = size ? 'text-' + size + 'xl' : 'text-2xl'
	return (
		<h1
			children={title}
			className={`text-center text-gray-700 font-medium my-8 ${textSize}`}
		/>
	)
}
export default Heading
