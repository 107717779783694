import FeedBack from './FeedBack'
import { FC } from 'react'
import Heading from '../heading/Heading'
import { IReview } from '../../interfaces/review.interface'

const FeedBackList: FC<{ reviews?: IReview[] }> = ({ reviews }) => {
	return (
			<section className='body-font'>
				<div className='max-w-6xl px-5 py-24 mx-auto'>
					<Heading title={'Відгуки:'} size={3} />
					<div className='flex flex-wrap -m-4'>
						{reviews?.map(feedback => (
							<FeedBack key={feedback.id} feedback={feedback} />
						))}
					</div>
				</div>
			</section>
	)
}
export default FeedBackList
