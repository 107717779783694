import { FC, PropsWithChildren } from 'react'
import Footer from './footer/Footer'
import Header from './header/Header'
import HeaderBanner from './header/HeaderBanner'

const Layout: FC<PropsWithChildren> = ({ children }) => {

	return (
		<div className="min-h-screen">
			<HeaderBanner />
			<Header />
			<main>
				{children}
			</main>
			<Footer />
		</div>
	)
}
export default Layout
