import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

export default function WeddingLoavesPage() {
	const { data } = useCachedKy<IProduct[]>(`weddingloaves`)

	return (
		<>
			<AppHelmet title="Весільні короваї" />
			<ProductList title="Весільні короваї:" products={data} />
		</>
	)
}
