import { FC } from 'react'
import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

const Cakes18Page: FC = () => {
	const { data } = useCachedKy<IProduct[]>('cakes18')
	return (
		<>
			<AppHelmet title="Торти 18+" />
			<ProductList title="Торти 18+:" products={data} />
		</>
	)
}

// Ця сторінка призначенна лише для повнолітніх осіб.
//   Заходячи на цю сторінку, я визнаю, що мені 18 років
// або більше.

export default Cakes18Page
